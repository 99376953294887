  import Alpine from "alpinejs";

  document.addEventListener('alpine:init', () => {
    // Define an Alpine.js component factory for handling payment values
    Alpine.data('paymentComponent', (selector) => ({
      paymentValue: '',
      loading: true,  // Track loading state

      // Fetch the payment value
      fetchPaymentValue() {
        this.waitForElement(selector, (content) => {
          if (content && content.trim() !== '') {
            this.paymentValue = this.extractPrice(content); // Extract the price
            this.loading = false; // Stop loading once a valid value is fetched
          } else {
            this.retryFetch(selector); // Retry fetching if content is invalid
          }
        });
      },

      // Retry fetching the element's content if it's not valid yet
      retryFetch() {
        setTimeout(() => {
          this.fetchPaymentValue(); // Retry fetching the payment value
        }, 300); // Retry every 300ms until the valid content is available
      },

      // Utility method to wait for an element with the provided selector
      waitForElement(selector, callback) {
        const interval = setInterval(() => {
          const element = document.querySelector(selector);
          if (element) {
            clearInterval(interval);
            callback(element.textContent); // Pass the element's content to the callback
          }
        }, 100); // Retry every 100ms until the element is found
      },

      // Extract only the price from a string, keeping digits, commas, and periods
      extractPrice(content) {
        // Regular expression to match digits, periods, and commas (to handle formatting)
        const priceMatch = content.match(/[\d,.]+/);
        return priceMatch ? priceMatch[0] : ''; // Return the matched price or empty string if not found
      }
    }));
  });