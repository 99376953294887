// import Swiper JS
import Swiper from "swiper";
// import Swiper styles
import "swiper/css";


document.addEventListener("DOMContentLoaded", function () {
	const swiper = new Swiper(".gallery-car", {
		slidesPerView: 1.4,
		loop: true,
		centeredSlides:true,
		spaceBetween: 6,
		autoHeight: true,
		navigation: {
			nextEl: '.ph-caret-right',
			prevEl: '.ph-caret-left'
		},
		breakpoints: {
			// when window width is >= 320px
			0: {
			},
			// when window width is >= 768px
			640: {
				slidesPerView:3.5
			}
		},
	});
});

document.addEventListener("DOMContentLoaded", function () {
	const swiper = new Swiper(".listCarsSwiper", {
		slidesPerView: 1.15,
		spaceBetween: 24,
		autoHeight:true,
		pagination: {
			el: ".swiper-pagination",	
			clickable: true,
		},
		breakpoints: {
			// when window width is >= 320px
			0: {
			},
			// when window width is >= 768px
			640: {
				slidesPerView:3.5
			}
		},
	});
});

document.addEventListener("DOMContentLoaded", function () {
	// Initialize Swiper without navigation configured
	const sliderGridItem = new Swiper(".sliderGridItem", {
		loop: true, // Keep looping enabled if desired
	});

	// Manually add event listeners to navigation buttons
	const prevButton = document.querySelector('.sliderGridItem-prev');
	const nextButton = document.querySelector('.sliderGridItem-next');

	// Check if buttons exist and add event listeners
	if (prevButton) {
		prevButton.addEventListener('click', function() {
			sliderGridItem.slidePrev();
		});
	}

	if (nextButton) {
		nextButton.addEventListener('click', function() {
			sliderGridItem.slideNext();
		});
	}

});
