/**
 * Event Tracking Module
 * Author: Thomas Ravnholt
 * 
 * This script listens for click events and window message events, pushing event data to the dataLayer.
 * 
 * How to Use:
 * 
 * 1. Listening for Click Events
 *    - Add `data-event="EVENT_NAME"` to any HTML element you want to track.
 *    - Optionally, add additional `data-KEY="VALUE"` attributes to pass more information.
 * 
 * 2. Listening for Window Messages
 *    - Send messages using `window.postMessage` containing an object with at least an `event` property.
 *    - The script listens for `message` events and pushes the message data to the dataLayer if it contains an `event` property.
 * 
 * ---
 * 
 * **Examples:**
 * 
 * **Click Event:**
 * ```html
 * <button data-event="buttonClick" data-category="navigation" data-label="homeButton">Home</button>
 * ```
 * 
 * **Window Message Event:**
 * ```javascript
 * // In another window or iframe
 * window.parent.postMessage({
 *   event: 'messageReceived',
 *   messageContent: 'Hello, world!'
 * }, window.location.origin); // Ensure the origin matches
 * ```
 */

 // **1. Listening for Click Events**
 // Add an event listener to the document for all click events
 document.addEventListener('click', function(event) {
  // Use 'closest' to find the nearest ancestor (or self) with the 'data-event' attribute
  const target = event.target.closest('[data-event]');
  
  // Proceed only if a matching element is found
  if (target && target.dataset && target.dataset.event) {
    // Initialize the dataLayer array if it doesn't exist
    window.dataLayer = window.dataLayer || [];
    
    // Destructure the 'event' property and collect the rest of the dataset
    const { event: eventName, ...eventData } = target.dataset;
    
    // Push an object to the dataLayer with the event name and additional data
    window.dataLayer.push({
      event: eventName,
      ...eventData
    });
  }
  
  // Uncomment the line below to enable debugging in the console
  // console.log('dataLayer:', window.dataLayer);
});

// **2. Listening for Window Messages**
// Add an event listener for message events
window.addEventListener('message', function(event) {
  // Security check: Ensure the message is from a trusted origin
  // Replace 'https://yourdomain.com' with your domain or use window.location.origin
  if (event.origin !== window.location.origin) {
    return;
  }
  
  // Extract data from the message event
  const messageData = event.data;
  
  // Check if the message contains an 'event' property
  if (messageData && messageData.event) {
    // Initialize the dataLayer array if it doesn't exist
    window.dataLayer = window.dataLayer || [];
    
    // Push the message data to the dataLayer
    window.dataLayer.push(messageData);
  }
  
  // Uncomment the line below to enable debugging in the console
  // console.log('dataLayer:', window.dataLayer);
});
