import axios from 'axios';

document.addEventListener('alpine:init', () => {
  Alpine.store('favorites', {
    shareLink: '',
    isShareLinkVisible: false,

    async addFavorite(id) {
      await this.handleFavoriteClick('add', id);
    },

    async removeFavorite(id) {
      await this.handleFavoriteClick('delete', id);
    },

    async handleFavoriteClick(action, id) {
      await axios.post(`/api/favorites/${action}?id=${id}`);
      this.$dispatch('favorite-updated', { id, action });
    },

    async createLink() {
      const response = await axios.post("/api/favorites/create-link?url=" + location.href);
      this.shareLink = response.data.link;
      this.isShareLinkVisible = true;
    },

    copyLink() {
      navigator.clipboard.writeText(this.shareLink);
    },

  });
});