import Alpine from "alpinejs";


document.addEventListener('alpine:init', () => {
  Alpine.data('evRange', (ranges) => ({
      temperature: 21,
      ranges: ranges,
      wltpRange: 300, // Replace with dynamic value if needed

      calculateRange(mode) {
          const worstTemp = -20;
          const bestTemp = 21.5;
          const maxTemp = 40;

          const minRange = this.ranges[mode].rated; // range at -20°C
          const maxRange = this.ranges[mode].real; // 100% at optimal temperature (21.5°C)
          const rangeAtForty = maxRange * 0.8; // 80% of max range at 40°C

          let rangePercentage;
          if (this.temperature <= bestTemp) {
              rangePercentage = ((this.temperature - worstTemp) / (bestTemp - worstTemp)) * 100;
          } else {
              rangePercentage = 100 - ((this.temperature - bestTemp) / (40 - bestTemp)) * 20;
          }

          if (this.temperature <= bestTemp) {
              return minRange + (rangePercentage / 100) * (maxRange - minRange);
          } else {
              return maxRange - ((this.temperature - bestTemp) / (maxTemp - bestTemp)) * (maxRange - rangeAtForty);
          }
      }
  }));
});
