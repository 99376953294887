import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
window.Alpine = Alpine;
Alpine.plugin(collapse);
Alpine.start();

import "./js/stores/globalSearchStore.js"; // Handles the globalsearch store and functionality
import "./js/stores/modalStore.js"; // Handles the modal store and functionality
import "./js/stores/favoritesStore.js";
import "./js/stores/darkModeStore.js"; // Handles the darkmode toggle
import "./js/components/paymentComponent.js"; // Fancybox config
import "./js/components/getPDFComponent.js"; // Fancybox config
import "./js/components/evDataComponent.js"; // Fancybox config
import "./js/utils/datalayer.js" // Handles events for tracking purposes
import "./js/configs/swiper.js"; // Handles swiper setup
import "./js/configs/animations.js"; // Handles all animations
import "./js/configs/fancybox.js"; // Fancybox config
