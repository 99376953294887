import Alpine from "alpinejs";

document.addEventListener('alpine:init', () => {
  // Global modal store to manage modals across the application
  // This store contains two properties:
  // - isOpen: A boolean to track whether the modal is open or closed
  // - modalType: A string to determine which type of modal to display (e.g., 'contact', 'info')

  Alpine.store('modalStore', {
    isOpen: false,  // Initially, the modal is closed
    modalType: '',  // Default empty modalType
    title: '',

    // Function to open the modal
    // Accepts a string argument 'type' to set the modal type (e.g., 'contact', 'info')
    open(type) {
      this.isOpen = true;  // Set the modal state to open
      this.modalType = type;  // Set the modal type to the passed argument

      // Add specific classes to the body element to prevent scrolling
      // and apply desired styling (e.g., padding, background)
      document.body.classList.add("pt-4", "px-4", "overflow-hidden", "bg-secondary");
    },

    // Function to close the modal
    close() {
      this.isOpen = false;  // Set the modal state to closed
      this.modalType = '';  // Optionally reset the modalType to default (empty)
      this.title = '';

      // Remove the classes from the body element to restore its default state
      // This re-enables scrolling and removes any padding or background applied
      document.body.classList.remove("pt-4", "px-4", "overflow-hidden", "bg-secondary");
    }
  });
});

/*
EXAMPLE USAGE:

1. Opening a Modal
   - You can trigger the opening of a modal with a button click and specify the modal type (e.g., 'contact').
   - This example shows how to open a "Contact" modal:
   
   <button @click="$store.modalStore.open('contact')">Open Contact Modal</button>

2. Closing the Modal
   - You can trigger the closing of the modal with a close button inside the modal.
   - This example shows how to close the modal:

   <button @click="$store.modalStore.close()">Close Modal</button>

3. Conditionally Displaying Modal Content
   - Based on the modalType, you can dynamically show different content inside the modal.
   - The content will change depending on the value of `$store.modalStore.modalType`:

   <div x-show="$store.modalStore.isOpen" class="fixed inset-0 bg-black bg-opacity-50 p-4">
       <template x-if="$store.modalStore.modalType === 'contact'">
           <div class="bg-white p-6 rounded-lg">
               <h2>Contact Modal</h2>
               <p>Content for the Contact modal goes here.</p>
               <button @click="$store.modalStore.close()">Close Modal</button>
           </div>
       </template>

       <template x-if="$store.modalStore.modalType === 'info'">
           <div class="bg-white p-6 rounded-lg">
               <h2>Info Modal</h2>
               <p>Content for the Info modal goes here.</p>
               <button @click="$store.modalStore.close()">Close Modal</button>
           </div>
       </template>
   </div>

4. Adding the Modal Wrapper
   - The modal container should be placed somewhere in your layout, using the Alpine store to control its visibility.
   - Make sure the modal listens to `$store.modalStore.isOpen` for visibility and uses the appropriate transitions:

   <div x-show="$store.modalStore.isOpen" class="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
       <!-- Modal content goes here -->
   </div>

   The body class styles will be added when the modal is open to prevent scrolling, and removed when it's closed.
*/

