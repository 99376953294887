import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);



/* gsap.from(".image-zoom", {
  scale: 1.1,
  duration: 20, // Animation duration
  ease: "power3.out",
  scrollTrigger: {
    trigger: ".image-zoom", // The parent element triggers the animation
    start: "top 75%", // Animation starts when the parent enters the viewport at 75%
    toggleActions: "play none none none", // Trigger actions: play on enter
  },
}); */

gsap.utils.toArray(".clip-parent").forEach((parent) => {
  gsap.to(parent.querySelectorAll(".clip-text"), {
    clipPath: " inset(0 0 0% 0)", // Fully reveal the text
    ease: "power2.inOut", // Add easing for smoother animation
    scrollTrigger: {
      trigger: parent,
      start: "top 70%", // Start earlier for more scroll space
      end: "center center", // End at the center for smoother reveal
      scrub: 2, // Make the animation scrub with the scroll
    },
  });
});

// Clip-path animation using fromTo to reveal text on scroll

const defaultAnimation = {
  opacity: 0,
  y: "25px",
  duration: 0.6,
  scale: 0.98,
  ease: "power2.out",
  filter: "blur(10px)",
  stagger: {
    amount: 0.3,
    each: 0.15,
    from: "start",
  },
};

function splitAndAnimateText() {
  const splitElements = document.querySelectorAll(
    "[data-split-text], [data-split-text] p"
  );

  if (splitElements.length === 0) return;

  splitElements.forEach((element) => {
    const splitText = new SplitType(element, { types: "lines" });

    gsap.from(splitText.lines, {
      ...defaultAnimation,
      scrollTrigger: {
        trigger: element,
        start: "top 80%",
        end: "bottom 20%",
        toggleActions: "play none none none",
      },
    });
  });
}

function scaleOnScrub() {
  const elements = document.querySelectorAll("[data-scrub-scale]");

  if (elements.length === 0) return;

  elements.forEach((element) => {
    gsap.to(element, {
      scale: 0.98,
      opacity: 0.1,
      scaleY: 0.8,
      filter: "blur(10px)",
      borderRadius: "4rem",
      scrollTrigger: {
        scrub: 1,
        trigger: element,
        start: "bottom 25%", 
        end: "bottom top",
        toggleActions: "play none none none",
      },
    });
  });
}


  // Function to animate individual items
  function animateSingleItems() {
    const singleItems = document.querySelectorAll("[data-animate-item]");

    if (singleItems.length === 0) return;

    singleItems.forEach((item) => {
      gsap.from(item, {
        ...defaultAnimation,
        scrollTrigger: {
          trigger: item,
          start: "top bottom",
          end: "bottom 20%",
          toggleActions: "play none none none",
        },
      });
    });
  };

// Function to animate children of elements with a stagger effect
function animateChildrenWithStagger() {
  const staggerContainers = document.querySelectorAll(
    "[data-animate-children]"
  );

  if (staggerContainers.length === 0) return;

  staggerContainers.forEach((container) => {
    const children = container.children;

    gsap.from(children, {
      ...defaultAnimation,
      stagger: {
        each: 0.1, // Stagger each child by 0.2 seconds
        from: "start", // Animate from the start of the children list
      },
      scrollTrigger: {
        trigger: container,
        start: "top bottom", // Adjust based on your desired animation start position
        end: "bottom 20%",
        toggleActions: "play none none none",
      },
    });
  });
}

// Run the function initially
scaleOnScrub();
splitAndAnimateText();
animateSingleItems();
animateChildrenWithStagger();
