import Alpine from "alpinejs";

document.addEventListener("alpine:init", () => {
    Alpine.store('darkModeStore', {
      // Initialize state
      isDarkMode: localStorage.getItem('dark-mode') === 'true',

      // Initialize function
      initialize() {
        if (this.isDarkMode) {
          document.documentElement.classList.add('dark');
        }
      },

      // Toggle dark mode
      toggleDarkMode() {
        this.isDarkMode = !this.isDarkMode;

        // Apply/remove dark mode class
        document.documentElement.classList.toggle('dark', this.isDarkMode);

        // Persist to localStorage
        localStorage.setItem('dark-mode', this.isDarkMode ? 'true' : 'false');
      },
    });
  });

