import Alpine from "alpinejs";

document.addEventListener('alpine:init', () => {
  // Define an Alpine.js component factory for handling PDF downloads
  Alpine.data('getPDFComponent', (url) => ({
    loading: false,   // Track loading state
    pdfReady: false,  // Track if PDF is ready to be viewed
    pdfBlobUrl: null, // Store the blob URL for the downloaded PDF

    // Fetch the PDF and prepare it for opening in a new tab
    async fetchPdf() {
      this.loading = true; // Start the loading state
      this.pdfReady = false; // Reset PDF ready state in case of re-fetch

      try {
        // Fetch the URL to get the PDF as a Blob
        const response = await fetch(url);

        if (response.ok) {
          // Get the PDF blob from the response
          const pdfBlob = await response.blob();
          // Create a Blob URL to be used to open the PDF in a new tab
          this.pdfBlobUrl = URL.createObjectURL(pdfBlob);
          this.pdfReady = true; // Mark PDF as ready to be viewed
        } else {
          console.error('Failed to fetch the PDF. Server responded with:', response.status);
        }
      } catch (error) {
        console.error('Error while fetching the PDF:', error);
      } finally {
        this.loading = false; // Reset loading state after fetching attempt
      }
    },

    // Open the PDF in a new tab when the button is clicked
    openPdf() {
      if (this.pdfBlobUrl) {
        window.open(this.pdfBlobUrl, '_blank'); // Open the blob URL in a new tab
      } else {
        console.error('No PDF Blob URL available');
      }
    }
  }));
});
